<template>
    <v-dialog
        v-if="visible"
        v-model="visible"
        scrollable
        persistent
        max-width="600"
    >
        <v-card class="modal-maincard">

            <v-toolbar dark flat dense>
                <v-toolbar-title>{{ $t(title) }}</v-toolbar-title>
            </v-toolbar>

            <v-card-text class="wrapperFormModal">
                <v-card 
                    flat
                >
                    <v-card-text
                        v-if="loading"
                    >
                        <v-col
                            class="text-subtitle-1 text-center"
                            cols="12"
                        >
                            {{ $t("Загрузка...") }}
                        </v-col>

                        <v-col cols="12">
                            <v-progress-linear
                                color="#a94442"
                                indeterminate
                                rounded
                                height="6"
                            ></v-progress-linear>
                        </v-col>

                    </v-card-text>
                    <v-card-text v-else>

                        <v-form ref="form" lazy-validation>

                            <v-row no-gutters v-if="isIncoming">
                                <v-col cols="12" sm="12" md="3">
                                    <label class="f-label">{{ $t("Директивный") }}</label>
                                </v-col>
                                <v-col cols="12" sm="12" md="9">
                                    <v-switch
                                        v-model="isDirective"
                                        inset
                                        hide-details
                                        class="cust-switch"
                                    />
                                </v-col>
                            </v-row>

                            <v-row no-gutters v-if="isIncoming || isOutgoingWithoutNomenclature">
                                <v-col cols="12" sm="12" md="3">
                                    <label class="f-label">{{ $t("Номенклатура") }}</label>
                                </v-col>
                                <v-col cols="12" sm="12" md="9">

                                    <v-row no-gutters>
                                        <v-select
                                            :value="nomenclature"
                                            @input="$store.dispatch('dialogs/registerDocument/onNomenclatureChange', { id: $event.id, Text: $event.Value })"
                                            return-object
                                            :items="nomenclatures"
                                            :item-text="item => $refLocale(item, $i18n.locale)"
                                            item-value="id"
                                            outlined
                                            dense
                                            append-icon="fas fa-chevron-down"
                                            required
                                            :rules="requiredRule"
                                            hide-details
                                            :menu-props="{ bottom: true, offsetY: true }"
                                        >

                                            <template v-slot:prepend-item>
                                                <v-list-item>
                                                    <v-list-item-content>
                                                        <v-text-field
                                                            :placeholder="$t('Поиск')"
                                                            class="mb-2 autocomplete-search"
                                                            hide-details
                                                            dense
                                                            clearable
                                                            autofocus
                                                            v-model="nomenclatureSearch"
                                                        >
                                                        </v-text-field>
                                                    </v-list-item-content>
                                                </v-list-item>
                                            </template>

                                        </v-select>
                                    </v-row>

                                </v-col>
                            </v-row>

                            <v-row no-gutters>
                                <v-col cols="12" sm="12" md="3">
                                    <label class="f-label">{{ $t("Журнал_регистрации") }}</label>
                                </v-col>
                                <v-col cols="12" sm="12" md="9">

                                    <v-row no-gutters>
                                        <v-select
                                            v-model="counter"
                                            return-object
                                            :items="counters"
                                            :item-text="item => $refLocale(item, $i18n.locale)"
                                            item-value="id"
                                            outlined
                                            dense
                                            append-icon="fas fa-chevron-down"
                                            required
                                            :rules="requiredRule"
                                            hide-details
                                            :menu-props="{ bottom: true, offsetY: true }"
                                        ></v-select>
                                    </v-row>

                                    <v-row no-gutters>
                                        <v-checkbox
                                            v-if="showPreviousYearCheckBox"
                                            v-model="includePreviousYear"
                                            :label="$t('Отображать_журналы_прошлого_года')"
                                            color="primary"
                                            hide-details
                                            dense
                                        ></v-checkbox>
                                    </v-row>

                                </v-col>
                            </v-row>

                            <v-row no-gutters>
                                <v-col cols="12" sm="12" md="3">
                                    <label class="f-label">{{ $t("Параметры_номера") }}</label>
                                </v-col>
                                <v-col cols="12" sm="12" md="9">

                                    
                                        <v-radio-group
                                            v-model="numberParam"
                                            dense
                                            mandatory
                                            hide-details
                                        >
                                            <v-radio
                                                :label="$t('По_порядку')"
                                                value="0"
                                            ></v-radio>
                                                                                    
                                            <v-radio
                                                value="1"
                                                v-if="reserve.length > 0"
                                            >
                                                <template v-slot:label>
                                                    <v-col
                                                        cols="4" sm="4" md="4"
                                                        class="ma-0 pa-0"
                                                    >
                                                        {{ $t('Из_резерва') }}
                                                    </v-col>
                                                    <v-col
                                                        cols="8" sm="8" md="8"
                                                        class="ma-0 pa-0"
                                                    >
                                                        <v-select
                                                            v-if="numberParam === '1'"
                                                            v-model="reserveNumber"
                                                            :items="reserve"
                                                            :item-text="item => $refLocale(item, $i18n.locale)"
                                                            item-value="id"
                                                            hide-details
                                                            outlined
                                                            dense
                                                            append-icon="fas fa-chevron-down"
                                                            :menu-props="{ bottom: true, offsetY: true }"
                                                        ></v-select>
                                                    </v-col>
                                                </template>
                                            </v-radio>
                                                                                
                                            <v-radio
                                                value="2"
                                            >
                                                <template v-slot:label>
                                                    <v-col
                                                        cols="4" sm="4" md="4"
                                                        class="ma-0 pa-0"
                                                    >
                                                        {{ $t('Другой') }}
                                                    </v-col>
                                                    <v-col
                                                        cols="8" sm="8" md="8"
                                                        class="ma-0 pa-0"
                                                    >
                                                        <v-text-field
                                                            v-if="numberParam === '2'"
                                                            v-model.trim="otherNumber"
                                                            dense
                                                            outlined
                                                            hide-details
                                                            type="number"
                                                            :min="1"
                                                        ></v-text-field>
                                                    </v-col>
                                                </template>
                                            </v-radio>
                                                                                    

                                            
                                            <v-radio
                                                value="3"
                                            >
                                                <template v-slot:label>
                                                    <v-col
                                                        cols="4" sm="4" md="4"
                                                        class="ma-0 pa-0"
                                                    >
                                                        {{ $t('Произвольный') }}
                                                    </v-col>

                                                    <v-col 
                                                        cols="8" sm="8" md="8"
                                                        class="ma-0 pa-0"
                                                    >   

                                                        <div class="more-inputs-in-row">                                          
                                                            <v-text-field
                                                                v-if="numberParam === '3'"
                                                                v-model.trim="customNumber"
                                                                dense
                                                                outlined
                                                                :placeholder="$t('Рег._№')"
                                                                single-line
                                                                hide-details
                                                            ></v-text-field>
                                                            <span></span>
                                                            <v-menu
                                                                v-if="numberParam === '3' && isIncoming && !isProtocol && !isNPA"
                                                                v-model="customRegDateMenu"
                                                                :close-on-content-click="false"
                                                                :nudge-right="40"
                                                                transition="scale-transition"
                                                                offset-y
                                                                min-width="290"
                                                            >
                                                                <template v-slot:activator="{ on, attrs }">
                                                                    <v-text-field
                                                                        :value="customRegDate | formattedDate"
                                                                        prepend-icon="mdi-calendar"
                                                                        readonly
                                                                        v-bind="attrs"
                                                                        v-on="on"
                                                                        outlined
                                                                        dense
                                                                        class="datepick-input"
                                                                        hide-details
                                                                    >
                                                                    </v-text-field>
                                                                </template>

                                                                <v-date-picker
                                                                    v-model="customRegDate"
                                                                    @input="customRegDateMenu = false"
                                                                    color="teal"
                                                                    :min="minCustomRegDate"
                                                                    :max="maxCustomRegDate"
                                                                    :first-day-of-week="1"
                                                                >
                                                                </v-date-picker>
                                                            </v-menu>
                                                        </div>
                                                    </v-col>

                                                </template>
                                            </v-radio>
                                        
                                        </v-radio-group>
                                    
                                </v-col>
                            </v-row>

                            <v-row no-gutters>
                                <v-col cols="12" sm="12" md="3">
                                    <label class="f-label">{{ $t("Номер_регистрации") }}</label>
                                </v-col>
                                <v-col cols="12" sm="12" md="9">

                                    <v-row no-gutters v-if="!loadingNumber">
                                        <div class="onlyReadData">
                                            
                                            <div v-if="potentialRegNumber.length > 0" :class="[potentialRegNumberError ? 'red--text' : '']">
                                                {{ potentialRegNumber }}
                                                <i v-if="!potentialRegNumberError">- {{ $t("предварительный") }}</i>
                                            </div>
                                            
                                            <div v-else>
                                                <i>{{ $t("Не_определено").toLowerCase() }}</i>
                                            </div>

                                        </div>
                                    </v-row>
                                    <v-row
                                        v-else
                                        no-gutters
                                    >
                                        <div class="onlyReadData" style="width:100%;">
                                            <v-progress-linear
                                                color="#a94442"
                                                indeterminate
                                                rounded
                                                height="6"
                                            ></v-progress-linear>
                                        </div>
                                    </v-row>

                                </v-col>
                            </v-row>

                            <v-row no-gutters v-if="isProtocol || isNPA">
                                <v-col cols="12" sm="12" md="3">
                                    <label class="f-label">{{ $t("Дата_регистрации") }}</label>
                                </v-col>
                                <v-col cols="12" sm="12" md="9">
                                    <v-menu
                                        v-model="customRegDateMenu"
                                        :close-on-content-click="false"
                                        :nudge-right="40"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="290"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                                :value="customRegDate | formattedDate"
                                                prepend-icon="mdi-calendar"
                                                readonly
                                                v-bind="attrs"
                                                v-on="on"
                                                outlined
                                                dense
                                                class="datepick-input"
                                                hide-details
                                            >
                                            </v-text-field>
                                        </template>

                                        <v-date-picker
                                            v-model="customRegDate"
                                            @input="customRegDateMenu = false"
                                            color="teal"
                                            :max="maxCustomRegDate"
                                            :first-day-of-week="1"
                                        >
                                        </v-date-picker>
                                    </v-menu>
                                </v-col>
                            </v-row>

                            <template v-if="canSendToInspect === true">
                                <v-divider class="marg-b-10"></v-divider>

                                <v-row no-gutters>
                                    <v-col cols="12" sm="12" md="12">
                                        <div class="onlyReadData">
                                            <v-checkbox
                                                v-model="sendToInspect"
                                                :label="$t('Отправить_на_ознакомление')"
                                                color="primary"
                                                hide-details
                                                dense
                                            ></v-checkbox>
                                        </div>
                                    </v-col>
                                </v-row>
                            </template>

                        </v-form>

                    </v-card-text>
                </v-card>
            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn
                    color="cyan"
                    text
                    depressed
                    @click="internalOk"
                    v-if="!loadingNumber && counter && !potentialRegNumberError && potentialRegNumber.length > 0"
                >
                    {{ $t("Зарегистрировать") }} 
                </v-btn>

                <v-btn
                    color="blue-grey"
                    text
                    depressed
                    @click="internalCancel"
                >
                    {{ $t("Отмена") }}
                </v-btn>

            </v-card-actions>

        </v-card>
    </v-dialog>
</template>

<script>
import _ from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import sys from '@/services/system';

export default {
    name: "RegisterDocumentDlg",
    data () {
        return {
            title: "Регистрация",
            options: {
                color: 'grey lighten-3',
                width: 400,
                zIndex: 200,
                noconfirm: false,
            },
            nomenclatureSearch: "",
            customRegDateMenu: false
        }
    },
    asyncComputed: {
        nomenclatures : {
            async get() {
                if (this.nomenclatureSearch) {                
                    return (await this.$store.dispatch('references/getNomenclatures', { forDepartment: false }))
                        .map(x => ({ id: x[0], Value: `${x[1]} ${x[2]}` }))
                        .filter(i => sys.checkSearch(i.Value, this.nomenclatureSearch) || i.id == this.nomenclature?.id);
                }
                else
                    return (await this.$store.dispatch('references/getNomenclatures', { forDepartment: false }))
                        .map(x => ({ id: x[0], Value: `${x[1]} ${x[2]}` }));
            },
            default: [],
            watch: ['nomenclatureSearch']
        }
    },
    filters: {
        formattedDate: function (value) {
            var formatedDate = sys.dateFormat(value, 'DD.MM.YYYY');
            return formatedDate ?? "";
        }
    },
    computed: {
        ...mapGetters({ 
            requiredRule: 'getRequiredRule',
        }),
        ...mapGetters('dialogs/registerDocument',
        {
            visible: 'isVisible',
            loading: 'isLoading',
            isIncoming: 'isIncoming',
            isProtocol: 'isProtocol',
            isNPA: 'isNPA',
            isOutgoingWithoutNomenclature: 'isOutgoingWithoutNomenclature',
            counters: 'getCounters',
            reserve: 'getReserve',
            potentialRegNumber: 'getPotentialRegNumber',
            showPreviousYearCheckBox: 'hasPreviousYearCounters',
            loadingNumber: 'isPotentialRegNumberLoading',
            potentialRegNumberError: 'isPotentialRegNumberError',
            minCustomRegDate: 'getMinCustomRegDate',
            maxCustomRegDate: 'getMaxCustomRegDate',
            canSendToInspect: 'isCanSendToInspect',
        }),
        counter: {
            get: function() {
                return this.$store.getters['dialogs/registerDocument/getCounter'];
            },
            set: function(v) {
                this.$store.dispatch('dialogs/registerDocument/onCounterChange', v);
            }
        },
        includePreviousYear: {
            get: function() {
                return this.$store.getters['dialogs/registerDocument/isIncludePreviousYear'];
            },
            set: function(v) {
                this.$store.dispatch('dialogs/registerDocument/onIncludePreviousYearChange', v);
            }
        },
        numberParam: {
            get: function() {
                return this.$store.getters['dialogs/registerDocument/getNumberParam'];
            },
            set: function(v) {
                this.$store.dispatch('dialogs/registerDocument/onNumberParamChange', v);
            }
        },
        reserveNumber: {
            get: function() {
                return this.$store.getters['dialogs/registerDocument/getReserveNumber'];
            },
            set: function(v) {
                this.$store.dispatch('dialogs/registerDocument/onReserveNumberChange', v);
            }
        },
        otherNumber: {
            get: function() {
                return this.$store.getters['dialogs/registerDocument/getOtherNumber'];
            },
            set: _.debounce(function(v) {
                this.$store.dispatch('dialogs/registerDocument/onOtherNumberChange', v);
            }, 500)
        },
        customNumber: {
            get: function() {
                return this.$store.getters['dialogs/registerDocument/getCustomNumber'];
            },
            set: _.debounce(function(v) {
                this.$store.dispatch('dialogs/registerDocument/onCustomNumberChange', v);
            }, 500)
        },
        customRegDate: {
            get: function() {
                return this.$store.getters['dialogs/registerDocument/getCustomRegDate'];
            },
            set: function(v) {
                this.$store.dispatch('dialogs/registerDocument/onCustomRegDateChange', v);
            }
        },
        isDirective: {
            get: function() {
                return this.$store.getters['dialogs/registerDocument/isDirective'];
            },
            set: function(v) {
                this.$store.commit('dialogs/registerDocument/SET_DIRECTIVE', v);
            }
        },
        sendToInspect: {
            get: function() {
                return this.$store.getters['dialogs/registerDocument/isNeedSendToInspect'];
            },
            set: function(v) {
                this.$store.commit('dialogs/registerDocument/SET_NEED_SEND_TO_INSPECT', v);
            }
        },
        nomenclature() {
            return this.$store.getters['dialogs/registerDocument/getNomenclature'];
        },
        selectNomenclatureTooltip() {
            return this.$t("Выбрать_номенклатуру");
        }
    },
    methods: {
        ...mapActions('dialogs/registerDocument', ['ok', 'cancel']),
        internalOk() {
            this.nomenclatureSearch = "";
            this.ok();
        },
        internalCancel() {
            this.nomenclatureSearch = "";
            this.cancel();
        },
        validateFormMethod(){
            this.$refs?.form?.validate();    
        }
    },
    updated: function () {
        this.validateFormMethod();
    }
}
</script>